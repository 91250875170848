#navbar .navbar-brand
{
    font-family: 'Garamond', 'Georgia', 'Times New Roman', 'Times', serif;
    font-size: 4rem;
    font-style: italic;
    background: #003358;
    color: white;
    width: 100%;
    border-bottom: 2px solid rgba(35, 160, 221, 0.25);
    line-height: 2rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}

#navbar .navbar-brand span
{
    color: #BBA38C;
    font-size: 2rem;
    vertical-align: middle;
}

#top {
    background: #003358;
}
#navbar
{
    max-width: 1400px;
    margin: 0 auto;
}

#home hr
{
    padding: 0px;
    margin: 0px;
}

#info-section
{
    color: white;
    margin: 0 auto;
    padding-bottom: 30px;
    max-width: 1400px;
}

#info-section p
{
    font-family: 'Garamond', 'Georgia', 'Times New Roman', 'Times', serif;
    font-size: 1.2rem;
}

#info-section p.links
{
    text-align: right;
    font-size: 1rem;
}

#info-section a
{
  font-family: 'Frutiger', 'Trebuchet', 'Helvetica', 'Arial', sans-serif;
    font-weight: 600;
    text-align: right;
}

#info-section a.active
{
    color: #BBA38C;
}

#search-section .input-group-text
{
    background-color: transparent;
}
#search-section
{
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* padding-left: 60px;
    padding-right: 60px; */

}

#search-section .link
{
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;

}

#search-section .link a
{
    text-decoration-line: underline;
}

#search-section i
{
    color: rgba(0, 0, 0, 0.1);
    padding-right: 0px;

}

#search-section input
{
    font-size: 1rem;
    padding: 1.5rem;
    border-radius: 0 5px 5px 0;
    border-left: none;
    padding-left: 0px;
}

#view-options
{
    margin-bottom: 4rem;
}

#view-options .btn
{
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
    border-radius: 0rem;
    color: #B18C5A;
    background-color: rgba(217, 203, 189, 0.2);
    outline: none;
    border: none;
    font-weight: 600;
    
    
    
}

#view-options .btn span
{
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
    font-weight: 600;
    position: relative;
    bottom: 10%;
}

#filters-section
{
    /* padding-left: 3rem;
    padding-right: 2rem; */
    /* padding-left: 60px;
    padding-right: 60px; */
}

#filters-section label
{
    font-weight: 400;
    font-size: 0.9rem;
    color: #8b8b8b;
    text-transform: uppercase;
}

#filters-section input, #filters-section select
{
    /* background-color: rgb(236, 236, 236); */
    /* border-radius: 0px; */
    border: 1px solid #999999;
}

#filters-section .btn
{
    color: #B18C5A;
    background-color: white;
    border: 2px solid #B18C5A;
    width: 100%;
    box-shadow: 4px 4px 0px #d6a96e;;
}

#table-section
{
    /* padding-left: 60px;
    padding-right: 60px; */
    margin-top: 4rem;
}

#footer
{
    margin-top: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* padding-left: 60px;
    padding-right: 60px; */
}

#footer p
{

    color: grey;
    font-size: 1rem;
    font-family: 'Garamond', 'Georgia', 'Times New Roman', 'Times', serif;
    margin-bottom: 0px;
}

#footer img
{
max-width: 100%;
}

#footer .logo-wrapper
{
    text-align: center;
}

/* Mailchimp styling */
#footer label {
    font-weight: 400;
    font-size: 0.9rem;
    color: #8b8b8b;
    margin-right: 1em;
    display: block;
}

#footer input
{
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
    margin-right: 10px;
}

#footer button
{
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #c0c0c0;
    margin-right: 10px;
}


#table-section
{
    overflow-x: auto;
}

#table-section .table-wrapper
{
    overflow-x: auto;
    border: 1px solid #c4c4c4;
}

#table-section td>div, #table-section th>div 
{
    font-size: 0.85rem;
    /* height: 1.6rem; */
    width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0.2rem;

}

#table-section tr:hover, #table-section tr:hover
{
    resize: horizontal;

}

#table-section th>div 
{
    height: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

#table-section td, #table-section th
{
    padding: 0.2rem;
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
    border: 1px solid rgb(236, 236, 236);
    border-bottom: 1px solid rgb(202, 202, 202);
    vertical-align: middle;
}

#table-section th
{
    background-color: #F6F6F6;
    font-weight: 500;
}

#table-section table i
{
    color: rgba(0,0,0,0.3);
    margin-left: 2rem;
    margin-right: 0.5rem;

}

#table-section table a
{
    text-decoration-line: underline;

}

#table-section .id-column
{
    width: 3rem;
}

#filters-section .filter-dropdown
{
    border: 1px solid rgb(206, 212, 218);
    height: 2.5rem;
    border-radius: 5px;
}
#filters-section .filter-dropdown:hover {
    border: 1px solid #B18C5A;
}

#filters-section .filter-dropdown-panel
{
    max-height: 20rem;
    overflow-y: auto;
    position: absolute;
    z-index: 1;
    background-color: white;
    border-radius: 5px;
    border: 2px solid #e7e7e7;
    padding-left: 0px;
    padding-right: 0px;
    /* box-shadow: 3px 3px 5px #f0f0f0; */
}

#filters-section .filter-dropdown-panel.filter-dropdown-panel-date
{
    width: max-content;
    max-width: max-content;
}

#filters-section .react-toggle-track
{
    width: 20px;
    height: 10px;
}

#filters-section .react-toggle-thumb
{
    height: 8px;
    width: 8px;
}

#filters-section .react-toggle--checked .react-toggle-thumb
{
    left: 10px;
}

#filters-section .option
{
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

#filters-section .option:hover
{
    /* background-color: #F6F6F6; */
}

#filters-section .option span
{
    margin-right: 0rem;
}

.active-view
{
    font-weight: 600;
    color: #3586FF;
}

.view-link
{
    cursor: pointer;
    margin-left: 0.5rem;
}

.view-link:hover
{
    color: #3586FF;
}

.links a
{
    cursor: pointer;
    color: white;
}

.links a:hover
{
    color: #9b7d62;
    text-decoration: none;
}

#pagination-wrapper .btn
{
    color: #B18C5A;
    outline: none;
    border: 2px solid #B18C5A;
    /* border-radius: 0px; */
    background-color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
}
#filters-section .filter-dropdown
{
    display: flex;
    align-items: center;
    padding-left: 0.25rem;
    height: 3rem;
}

.filter-dropdown p
{
    background-color: rgb(255, 194, 194);
    color: black;
    margin-bottom: 0px;
    padding: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 1rem;
    border-radius: 1rem;
}

.record-index
{
    display: flex;
    align-items: center;
    padding-left: 0px;

}

.record-index  p
{
    margin-bottom: 0px;
}

.icon-wrapper img
{
    height: 25px;
    width: auto;
    cursor: pointer;
}

#table-section td > div.table-company
{
    border-radius: 1rem;
    padding: 0.2rem 0.8rem;
    width: fit-content;
}

#table-section td > div.table-network
{
    /* background-color: rgb(235, 235, 243); */
    /* border-radius: 1rem; */
    
    width: 350px;
    overflow: auto;
}

#table-section td > div.table-network p
{
    background-color: rgb(235, 235, 243);
    border-radius: 1rem;
    padding: 0.2rem 0.8rem;
    display: inline-block;
    margin-left: 0.2rem;
    margin-bottom: 0;
}

.limit-dropdown
{
    border: 2px solid #B18C5A;
    color: #B18C5A;
    margin-top: 1rem;
    text-align: center;
    padding: 0.375rem;
    /* display: inline; */
    /* margin-left: 1rem */
}

.limit-dropdown-wrapper
{
    margin-top: 1rem;
}

.filter-dropdown-panel.limit-dropdown-options
{
    position: absolute;
    z-index: 100;
}

.filter-dropdown
{

}

.filter-dropdown p.no-filter
{
    background-color: transparent;
    width: 100%;
    color: #999999;
    cursor: pointer;

}

.filter-dropdown p span
{
    position: absolute;
    right: 1rem;
}

#pagination-section
{
    /* padding-left: 60px;
    padding-right: 60px; */
}

.limit-dropdown-options
{
    background-color: white;
    border-radius: 5px;
    border: 2px solid #e7e7e7;
}

.filter-dropdown .pill
{
    margin-right: 0.2rem;
    min-width: max-content;
    padding-right: 0.5rem;
}

.filter-dropdown .pill span
{
    position: relative;
    width: 1rem;
    right: 0rem;
    margin-left: 1rem;
    /* background-color: rgba(255,255,255,0.5); */
    padding: 0.35rem;
    border-radius: 1rem;
    font-size: 0.65rem;
    cursor: pointer;
    color: rgb(61, 61, 61)
}

.filter-dropdown .pill span:hover
{
    color: black;
}

.filter-dropdown p.Facebook, p.Facebook
{
    background-color: rgba(59, 89, 152, 0.2);

}

.filter-dropdown p.Twitter, p.Twitter
{
    background-color: rgba(0, 172, 238, 0.2);

}

.filter-dropdown p.Reddit, p.Reddit
{
    background-color: rgba(255, 87, 0, 0.2);

}

.filter-dropdown p.GoogleYoutube, p.GoogleYoutube
{
    background-color: rgb(196, 48, 43, 0.2);
}

.filter-dropdown p.Grapika, p.Grapika
{
    background-color: #AAAAAA;

}
.filter-dropdown p.DFRLab, p.DFRLab
{
    background-color: rgb(0, 134, 125, 0.2);

}

.filter-dropdown 
{
    overflow-x: auto;
}

.filter-dropdown .time-filter
{
    background-color: #ECECEC;
    min-width: max-content;
    border-radius: 1rem;
}

.row-modal.modal-description-section
{
    padding-top: 2rem;
    padding-bottom: 4rem;
    cursor: auto;
}

#table-section tr:hover
{
background-color: rgb(241, 241, 241);
cursor: pointer;
}

.modal-value
{
    max-width: max-content;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-radius: 1rem;
}


.row-modal .Company
{
    padding: 0.2rem 0.8rem;
    font-size: 0.85rem;
    cursor: pointer;
    max-width: max-content;
    border-radius: 1rem;
}


.row-modal .Networks
{
    background-color: rgb(255, 255, 255);
    padding: 0.2rem 0.8rem;
    font-size: 0.85rem;
    border-radius: 1rem;
    cursor: pointer;
    max-width: max-content;
}

.download-csv
{
    /* color: #B18C5A;
    border-bottom: 2px solid #B18C5A;
    margin-bottom: 0px; */
    display: grid;
    align-items: center;
    padding-left: 0;

}
#pagination-wrapper .download-csv .btn
{
    width: max-content;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0;
    margin-top: 0px;
    outline: none;
}

.download-csv p
{
    color: #B18C5A;
    text-decoration-line: underline;
    margin-bottom: 0px;
    cursor: pointer;
}

.download-csv p:hover
{
    color: #9e6b28;
}

.sort-content-link
{
    color: #B18C5A;
    text-decoration-line: underline;
    cursor: pointer;
}

.sort-content-link i
{
    margin-right: 0.5rem;
}

.sort-content-link:hover
{
    color: #9e6b28;
}

.filters-section-collapser
{
    color: #B18C5A;
    cursor: pointer;
}

.filters-section-collapser:hover
{
    color: #9e6b28;
}


.modal-body .carousel .slide
{
    background: #e9f4ff;
}

.fa-times
{
    cursor: pointer;
    font-size: 0.65rem;
}

@media (max-width: 576px) { 
    .wrapper
    {
        height: auto;
    }
 }

 