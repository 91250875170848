._card
{
    /* height: 300px;
    overflow: hidden; */
}
._card .wrapper:hover {
    box-shadow: 0 0 15px rgba(0,0,0, 0.25);
    transition: 300ms;
}

.subtitle
{
    text-transform: uppercase;
    color: #8b8b8b;
    font-size: 0.9rem;
    margin-bottom: 2px;
}

.network
{
    background-color: #D5EFFB;
    border-radius: 1rem;
    padding: 0.3rem 0.5rem 0.2rem;
    font-size: 0.9rem;
    line-height: 1rem;
    transition: 300ms;
}
.network:hover, .platform:hover, .disclosure:hover {
    filter: brightness(1);
    transition: 300ms;
}



.platform
{
    background-color: rgb(231, 231, 231);
    border-radius: 1rem;
    padding: 0.3rem 0.5rem 0.2rem;
    font-size: 0.9rem;
    margin-right: 0.3rem;
    /* color: white; */
}

.type:not(:last-child):after {
    content: ", ";
}

.type, .active-date
{
    font-size: 1rem;

}

.wrapper
{
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    height: 360px;
    overflow: hidden;
    /* padding: 1rem; */
}

.company-logo
{
    max-width: 60%;

}

.company-logo-wrapper
{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #EEEEEE;
}

.company-logo-wrapper img
{
    position: absolute;
    margin: auto;
    opacity: 0.5;
}

.company-logo-wrapper p
{
    margin-top: auto;
    color: rgb(92, 92, 92);
    font-size: 0.85rem;
}

.description-section
{
    padding: 1rem;
    cursor: pointer;
}

.cards-pagination-wrapper
{
    text-align: center;
    margin-top: 2rem;
    color: rgb(168, 168, 168);
}

.cards-pagination-wrapper span
{
    cursor: pointer;
    /* text-decoration-line: underline; */
    margin-left: 0.4rem;
    color: rgb(168, 168, 168);
    /* font-size: 0.9rem; */
}

.cards-pagination-wrapper span:hover
{
    color: black;
    text-decoration-line: underline;
}



.cards-pagination-wrapper span.active
{ 
    color: black;
    font-size: 1rem;
    font-weight: 900;
}


.modal-description-section
{
    background-color: #ECECEC;
}

.modal-body
{
    max-height: 80vh;
    overflow: auto;
}

.modal-body
{
    padding: 0px;
}

.description.section
{
    max-height: 20rem;
    overflow: auto;
}

/* .description.section p
{
    font-size: 0.75rem;
} */

/* .description.section .subtitle
{
    margin-top: 1rem;
    font-size: 0.6rem;
} */

.description
{background-color: #ECECEC;
}

p.divider
{
    margin: 1rem;
}

p.divider-body
{
    margin: 1rem;
    font-weight: 400; 
}

.row-modal.description-section.modal-description-section p.platform_record_title
{
    font-size: 0.95rem;
    padding-left: 1rem;
}

.COMPANY
{
    padding: 0.2rem;
    width: max-content;
    border-radius: 1rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.alert-message
{
    margin-top: 2rem;
    text-align: center;
    font-weight: bolder;
}

.modal-body
{
    background-color: #ECECEC;
}

.description-section .disclosure
{
    background-color: rgb(231, 231, 231);
    border-radius: 1rem;
    padding: 0.3rem 0.5rem 0.2rem;
    font-size: 0.9rem;
    width: max-content;
    cursor: pointer;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.carousel .slide
{
    background: #ECECEC;
}

@media (max-width: 1024px) { 

.hidden-xs
{
    display: none;
}
}

.single-line
{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.comma:not(:empty):not(:last-child):after {
    content: ", ";
  }