#sync .btn
{
    color: white;
    padding: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


#sync-table .sync-td
{
    background: rgb(245, 245, 245);
    border: 2px solid white;
    text-overflow: ellipsis;
    overflow: hidden;
  font-family: 'Frutiger', 'Trebuchet', 'Helvetica', 'Arial', sans-serif;
    padding: 1rem;

}

#sync-table .sync-th
{   
    background: #003358; 
    color: white;
    /* background: rgb(245, 245, 245); */
    border: 2px solid white;
    /* font-weight: 700; */
    font-family: 'Garamond', 'Georgia', 'Times New Roman', 'Times', serif;
    font-style: italic;
    padding: 1rem;

}